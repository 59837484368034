import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter, UsersGroupsSearchFilter, UsersSearchResult } from 'app/users-groups/models/users-groups-search.model';
import { UsersGroupsService } from 'app/users-groups/services/users-groups.service';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ODataResponse } from '@sk-models';

@Component({
  selector: 'sk-users-search-grid',
  templateUrl: './users-search-grid.component.html',
  styleUrls: ['./users-search-grid.component.scss']
})
export class UsersSearchGridComponent  extends SkyKickComponent implements OnInit {

  @Input() backupServiceId: string;
  @Input() groupId: string;
  @Input() filterSearchTerm: string;

  public isLoading = true;
  public hasData = false;

  // Pagination
  pageSizeOptions = [12, 50, 100];
  totalResults: number;

  subject = new Subject();
  filter: UsersGroupsSearchFilter;

  dataSource: MatTableDataSource<UsersSearchResult>;
  displayedColumns = ['id', 'name', 'protected', 'hasMailbox', 'hasOneDrive', 'hasTeamsChats', 'disabledDueToAccess', 'created', 'isv2'];

  public ExchangeIcon = '../../../../../../assets/images/icon-exchange.svg';
  public OneDriveIcon = '../../../../../../assets/images/icon-onedrive.svg';
  public TeamsChatsIcon = '../../../../../../assets/images/icon-teams.svg';

  constructor(private usersGroupsService: UsersGroupsService) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.subs.sink = this.subject.pipe(
      switchMap((data: UsersGroupsSearchFilter) => {
        this.isLoading = true;
        return this.executeSearch(data);
      })
    ).subscribe((uag: ODataResponse<UsersSearchResult>) => {
      this.hasData = true;
      this.totalResults = uag['@odata.count'];
      this.dataSource = new MatTableDataSource(uag.value);
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
    this.filter = {
      page: 0,
      size: this.pageSizeOptions[0],
      sort: 'name',
      sortDirection: 'asc',
      term: this.filterSearchTerm };
  }

  public refresh(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.subject.next(this.filter);
  }

  private executeSearch(filter: UsersGroupsSearchFilter): Observable<ODataResponse<UsersSearchResult>> {
    if (this.groupId) {
      return this.usersGroupsService
        .searchGroupUserMemberships(this.backupServiceId, this.groupId, filter);
    } else {
      return this.usersGroupsService
        .searchUsers(this.backupServiceId, filter);
    }
  }

  onPageChanged(event: PageEvent): void {
    this.filter.size = event.pageSize;
    this.filter.page = event.pageIndex;
    this.subject.next(this.filter);
  }

  onFilterChanged(term: string): void {
    this.filter = {
      ...this.filter,
      term: term
    };
  }

  onSortChanged(sort: Sort): void {
    this.filter.sort = sort.active;
    this.filter.sortDirection = (sort.direction);
    this.subject.next(this.filter);
  }
}
