import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
/* eslint-disable max-len */
// Angular or 3rd party modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IAppEnvironment } from 'environments/config/environment.model';
// Environment Data
import { environment } from 'environments/environment';

import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  BrowserUtils,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  BaseRealUserMonitoringService,
  BookmarksService,
  DataDogRealUserMonitoringService,
  EntityDownloadService,
  JobsService,
  MessageService,
  NullRealUserMonitoringService,
  OrderService,
  PromoService,
  SearchReindexService,
  SkyKickApiService,
  SubscriptionSearchService,
  SubscriptionService,
  TabsDispatchService,
  ToastService,
  WindowService,
} from '@sk-services';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import {
  AccessAppAuthComponent,
} from './access/access-app-auth.component';
// Services
import { ApiService } from './api-service/api.service';
import { MomentDatesInterceptor } from './api-service/moment-dates.interceptor';
// Interceptors
import {
  ResponseCacheInterceptor,
} from './api-service/response-cache.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingComponent } from './accounting/billing/billing.component';
import { CustomMaterialModule } from './custom-material/custom-material.module';
// Components
import { DeveloperComponent } from './developer/developer.component';
import {
  ExplorerComponent,
} from './graph-explorer/explorer/explorer.component';
import {
  ExplorerV2Component,
} from './graph-explorer/explorer-v2/explorer-v2.component';
import {
  GraphTokenModalComponent,
} from './graph-explorer/graph-token-modal/graph-token-modal.component';
import {
  GraphExplorerComponent,
} from './graph-explorer/graph-explorer.component';
import { GraphJourneyModule } from './graph-journey/graph-journey.module';
import { IpCheckerComponent } from './ip-checker/ip-checker.component';
import {
  AuditFilterComponent,
} from './order/audit-filter/audit-filter.component';
import {
  BulkOperationsComponent,
} from './order/order-actions/bulk-operations/bulk-operations.component';
import {
  OrderActionsComponent,
} from './order/order-actions/order-actions.component';
import {
  OrderDetailsComponent,
} from './order/order-details/order-details.component';
import {
  OrderJobHistoryComponent,
} from './order/order-job-history/order-job-history.component';
import {
  LicensingDrawerDetailsComponent,
} from './order/order-licensing/licensing-drawer-details/licensing-drawer-details.component';
import {
  LicensingDrawerComponent,
} from './order/order-licensing/licensing-drawer/licensing-drawer.component';
import {
  OrderLicensingComponent,
} from './order/order-licensing/order-licensing.component';
import {
  OrderReindexHistoryComponent,
} from './order/order-reindex-history/order-reindex-history.component';
import {
  OrderRestoreHistoryComponent,
} from './order/order-restore-history/order-restore-history.component';
import { OrderComponent } from './order/order.component';
import {
  SnapshotHealthComponent,
} from './order/snapshot-health/snapshot-health.component';
import {
  TenantUsersComponent,
} from './order/tenant-users/tenant-users.component';
import { OrdersGridComponent } from './orders-grid/orders-grid.component';
import {
  PartnerCardComponent,
} from './partner-finder/partner-card/partner-card.component';
import {
  PartnerFinderComponent,
} from './partner-finder/partner-finder.component';
import { SubscriptionTypePipe } from './pipes/subscription-type';
import {
  PromoDetailsComponent,
} from './accounting/promo-details/promo-details.component';
import { AccountingComponent } from './accounting/accounting.component';
import {
  RestoreHistoryDetailsComponent,
} from './restores/restore-history-details/restore-history-details.component';
import {
  RestoreHistoryComponent,
} from './restores/restore-history/restore-history.component';
import {
  RestoreJobHistoryComponent,
} from './restores/restore-job-history.component';
import {
  JobSearchInputComponent,
} from './search-overview/job-search-input/job-search-input.component';
import {
  OrderSearchInputComponent,
} from './search-overview/order-search-input/order-search-input.component';
import {
  OrderSummaryCardComponent,
} from './search-overview/order-summary-card/order-summary-card.component';
import {
  SearchOverviewComponent,
} from './search-overview/search-overview.component';
import {
  SubscriptionSearchInputComponent,
} from './search-overview/subscription-search-input/subscription-search-input.component';
import {
  SubscriptionSummaryCardComponent,
} from './search-overview/subscription-summary-card/subscription-summary-card.component';
import {
  AppSettingsService,
} from './services/app-settings/app-settings.service';
import { BadgeComponent } from './shared/badge/badge.component';
import { BannerComponent } from './shared/banner/banner.component';
import {
  BookmarkListComponent,
} from './shared/bookmark-list/bookmark-list.component';
import { BookmarkComponent } from './shared/bookmark/bookmark.component';
import { CrmIframeUtil } from './shared/crm-iframe-util';
import {
  SkSharedDirectivesModule,
} from './shared/directives/sk-shared-directives.module';
import {
  DropdownElementComponent,
} from './shared/dropdown-element/dropdown-element.component';
import {
  GenericModalComponent,
} from './shared/generic-modal/generic-modal.component';
import {
  JobDetailsComponent,
} from './shared/job-details/job-details.component';
import {
  JobHistoryDayRangeComponent,
} from './shared/job-history-day-range/job-history-day-range.component';
import {
  JobHistoryComponent,
} from './shared/job-history/job-history.component';
import {
  JobProgressDetailsComponent,
} from './shared/job-progress-details/job-progress-details.component';
import {
  JobRequestedByComponent,
} from './shared/job-requested-by/job-requested-by.component';
import {
  JobResultsComponent,
} from './shared/job-results/job-results.component';
import { QueueJobComponent } from './shared/queue-job/queue-job.component';
import {
  ReindexHistoryFilterComponent,
} from './shared/reindex-history/reindex-history-filter/reindex-history-filter.component';
import {
  ReindexHistoryComponent,
} from './shared/reindex-history/reindex-history.component';
import {
  ResourceEventsComponent,
} from './shared/resource-events/resource-events.component';
import {
  RestoreDetailsComponent,
} from './shared/restore-details/restore-details.component';
import {
  RestoreResultExportComponent,
} from './shared/restore-result-export/restore-result-export.component';
import {
  RestoreSkippedItemsExportComponent,
} from './shared/restore-skipped-items-export/restore-skipped-items-export.component';
import {
  RestoredItemsModalComponent,
} from './shared/restored-items/restored-items-modal/restored-items-modal.component';
import {
  RestoredItemsComponent,
} from './shared/restored-items/restored-items.component';
import { SharedModule } from './shared/shared.module';
import {
  SkippedRestoreItemsReportComponent,
} from './shared/skipped-restore-items-report/skipped-restore-items-report.component';
import {
  SkippedRestoreItemsModalComponent,
} from './shared/skipped-restore-items/skipped-restore-items-modal.component/skipped-restore-items-modal.component';
import {
  SkippedRestoreItemsComponent,
} from './shared/skipped-restore-items/skipped-restore-items.component';
import {
  SnapshotDetailsComponent,
} from './shared/snapshot-details/snapshot-details.component';
import {
  SubMenuLinksComponent,
} from './shared/subtab-menu-links/subtab-menu-links.component';
import { SubtabNavComponent } from './shared/subtab-nav/subtab-nav.component';
import {
  SwatTicketListComponent,
} from './shared/swat-ticket-list/swat-ticket-list.component';
import {
  SwatTicketComponent,
} from './shared/swat-ticket/swat-ticket.component';
import { TabLinkComponent } from './shared/tab-nav/tab-link/tab-link.component';
import {
  TargetRestoreItemsModalComponent,
} from './shared/target-restore-items/target-restore-items-modal.component/target-restore-items-modal.component';
import {
  TargetRestoreItemsComponent,
} from './shared/target-restore-items/target-restore-items.component';
import {
  VersionDialogComponent,
} from './shared/version-dialog/version-dialog.component';
import {
  SubscriptionBrowserComponent,
} from './subscriptions/subscription-browser/subscription-browser.component';
import {
  TreeDetailsComponent,
} from './subscriptions/subscription-browser/tree-details/tree-details.component';
import {
  TreeViewerSearchComponent,
} from './subscriptions/subscription-browser/tree-viewer/tree-viewer-search/tree-viewer-search.component';
import {
  TreeViewerComponent,
} from './subscriptions/subscription-browser/tree-viewer/tree-viewer.component';
import {
  SubscriptionDashboardComponent,
} from './subscriptions/subscription-dashboard/subscription-dashboard.component';
import {
  ElasticDetailsModalComponent,
} from './subscriptions/subscription-details/elastic-details-modal/elastic-details-modal.component';
import {
  BlobSizeModalComponent,
} from './subscriptions/subscription-details/blob-size-modal/blob-size-modal.component';
import {
  LicenseDetailsModalComponent,
} from './subscriptions/subscription-details/license-details/license-details-modal.component/license-details-modal.component';
import {
  LicenseDetailsComponent,
} from './subscriptions/subscription-details/license-details/license-details.component';
import {
  MachineDetailsComponent,
} from './subscriptions/subscription-details/machine-details/machine-details.component';
import {
  PurgeModalComponent,
} from './subscriptions/subscription-details/purge-results/purge-modal.component';
import {
  ReindexModalComponent,
} from './subscriptions/subscription-details/reindex/reindex-modal.component';
import {
  SubscriptionDetailsComponent,
} from './subscriptions/subscription-details/subscription-details.component';
import {
  SubscriptionGridComponent,
} from './subscriptions/subscription-grid/subscription-grid.component';
import {
  SubscriptionHealthComponent,
} from './subscriptions/subscription-health/subscription-health.component';
import {
  SubscriptionJobHistoryComponent,
} from './subscriptions/subscription-job-history/subscription-job-history.component';
import {
  SubscriptionReindexHistoryComponent,
} from './subscriptions/subscription-reindex-history/subscription-reindex-history.component';
import {
  EntityDetailComponent,
} from './subscriptions/subscription-search/entity-detail/entity-detail.component';
import {
  SubscriptionSearchComponent,
} from './subscriptions/subscription-search/subscription-search.component';
import {
  SubscriptionComponent,
} from './subscriptions/subscription/subscription.component';
import {
  SubscriptionsFilterComponent,
} from './subscriptions/subscriptions-filter/subscriptions-filter.component';
import { UsersGroupsModule } from './users-groups/users-groups.module';
import { PartnerSearchComponent } from './partner-search/partner-search.component';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { BulkReindexModalComponent } from './order/order-actions/bulk-reindex-modal/bulk-reindex-modal.component';
import { firstValueFrom } from 'rxjs';

/**
 * Factory method used by the provider for instantiating an instance of a BaseRealuserMonitoringService
 * @returns instance of BaseRealUserMonitoringService
 */
export function RealUserMonitoringFactory(): BaseRealUserMonitoringService {

  const isRumEnabled = (targetEnvironment: IAppEnvironment): boolean => {
    if (targetEnvironment.rumClient) {
      const hasValidToken = targetEnvironment.rumClient.clientToken?.length > 0
        && targetEnvironment.rumClient.applicationId?.length > 0
        && targetEnvironment.rumClient.isEnabled;
      return hasValidToken;
    }
    return false;
  };

  let rumClient: BaseRealUserMonitoringService;
  if (isRumEnabled(environment)) {
    rumClient = new DataDogRealUserMonitoringService();
  } else {
    rumClient = new NullRealUserMonitoringService();
  }
  return rumClient;
}

@NgModule({
  declarations: [
    DeveloperComponent,
    AppComponent,
    OrderComponent,
    AccountingComponent,
    BillingComponent,
    TabLinkComponent,
    SubtabNavComponent,
    SubMenuLinksComponent,
    SubscriptionTypePipe,
    DropdownElementComponent,
    TenantUsersComponent,
    JobDetailsComponent,
    SnapshotHealthComponent,
    QueueJobComponent,
    BadgeComponent,
    OrderDetailsComponent,
    PromoDetailsComponent,
    JobHistoryComponent,
    OrderJobHistoryComponent,
    OrderActionsComponent,
    SubscriptionDetailsComponent,
    SubscriptionHealthComponent,
    SubscriptionComponent,
    SubscriptionJobHistoryComponent,
    JobResultsComponent,
    SubscriptionBrowserComponent,
    TreeViewerComponent,
    TreeDetailsComponent,
    GenericModalComponent,
    GraphExplorerComponent,
    SearchOverviewComponent,
    ExplorerComponent,
    ExplorerV2Component,
    GraphTokenModalComponent,
    OrderSummaryCardComponent,
    RestoreDetailsComponent,
    SkippedRestoreItemsComponent,
    TargetRestoreItemsComponent,
    ResourceEventsComponent,
    SkippedRestoreItemsModalComponent,
    TargetRestoreItemsModalComponent,
    MachineDetailsComponent,
    RestoreJobHistoryComponent,
    RestoreHistoryDetailsComponent,
    AccessAppAuthComponent,
    PartnerFinderComponent,
    PartnerCardComponent,
    PartnerSearchComponent,
    PartnerDetailsComponent,
    OrdersGridComponent,
    SkippedRestoreItemsReportComponent,
    JobRequestedByComponent,
    SubscriptionGridComponent,
    SubscriptionDashboardComponent,
    SubscriptionsFilterComponent,
    AuditFilterComponent,
    JobRequestedByComponent,
    LicenseDetailsComponent,
    LicenseDetailsModalComponent,
    IpCheckerComponent,
    OrderRestoreHistoryComponent,
    OrderReindexHistoryComponent,
    RestoreHistoryComponent,
    BulkOperationsComponent,
    PurgeModalComponent,
    RestoredItemsComponent,
    RestoredItemsModalComponent,
    RestoreResultExportComponent,
    RestoreSkippedItemsExportComponent,
    ReindexModalComponent,
    SubscriptionReindexHistoryComponent,
    ReindexHistoryFilterComponent,
    ReindexHistoryComponent,
    OrderLicensingComponent,
    BookmarkListComponent,
    BannerComponent,
    VersionDialogComponent,
    SubscriptionSummaryCardComponent,
    OrderSearchInputComponent,
    SubscriptionSearchInputComponent,
    JobSearchInputComponent,
    BookmarkListComponent,
    BookmarkComponent,
    JobProgressDetailsComponent,
    SnapshotDetailsComponent,
    SwatTicketComponent,
    SwatTicketListComponent,
    TreeViewerSearchComponent,
    JobHistoryDayRangeComponent,
    LicensingDrawerDetailsComponent,
    LicensingDrawerComponent,
    ElasticDetailsModalComponent,
    BlobSizeModalComponent,
    SubscriptionSearchComponent,
    EntityDetailComponent,
    BulkReindexModalComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    CustomMaterialModule,
    NgxChartsModule,
    ReactiveFormsModule,
    FormsModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.azureActiveDirectory.clientId,
        authority: environment.azureActiveDirectory.authority,
        redirectUri: environment.client_url(),
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        [environment.api_url(), ['https://skykick.com/721e184d-cfce-4377-b236-00dee5f57961/user_impersonation']],
        [environment.odata_url(), ['https://skykick.com/721e184d-cfce-4377-b236-00dee5f57961/user_impersonation']]
      ])
    }),
    SkSharedDirectivesModule,
    GraphJourneyModule,
    UsersGroupsModule,
    SharedModule
  ],
  providers: [
    ApiService,
    MsalService,
    MsalGuard,
    MsalBroadcastService, // do we need this?
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [MsalService],
      useFactory: (authService: MsalService) => {
        return () => {
          if(BrowserUtils.isInIframe()) {
            return firstValueFrom(authService.ssoSilent({
              scopes: []
            }));
          }
        };
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseCacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MomentDatesInterceptor,
      multi: true
    },
    {
      provide: BaseRealUserMonitoringService,
      useFactory: RealUserMonitoringFactory,
      multi: false
    },
    MessageService,
    OrderService,
    PromoService,
    JobsService,
    SearchReindexService,
    TabsDispatchService,
    SubscriptionService,
    SubscriptionSearchService,
    CrmIframeUtil,
    WindowService,
    AuthService,
    ToastService,
    BookmarksService,
    EntityDownloadService,
    AppSettingsService,
    SkyKickApiService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule {
}
