import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sk-graph-explorer',
  templateUrl: './graph-explorer.component.html',
  styleUrls: ['./graph-explorer.component.scss']
})
export class GraphExplorerComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  orderId: string;
  activeTab: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('id');
  }
}
