<div *ngIf="treeRoot" class="entity-details">
	<div class="table tree-details">
		<div class="row">
			<span class="label">Name:</span>
			<span class="value">{{treeRoot.name}}</span>
		</div>
		<div class="row">
			<span class="label">Relative URL:</span>
			<span class="value">{{treeRoot.relativeURL}}</span>
		</div>
    <div class="row">
			<span class="label">Friendly URL:</span>
			<span class="value">{{treeRoot.friendlyURL}}</span>
		</div>
		<div class="row">
			<span class="label">SkyKickId:</span>
			<span class="value">{{treeRoot.skyKickId}}</span>
		</div>
		<div class="row">
			<span class="label">ParentId:</span>
			<span class="value">{{treeRoot.parentId}}</span>
		</div>
    <div class="row">
      <span class="label">NativeId:</span>
      <span class="value">{{treeRoot.nativeId}}
        <a *ngIf="graphExplorerUri"
          class="link-button"
          target="_blank"
          routerLink="/orders/{{orderId}}/graph-explorer2"
          routerLinkActive="true"
          [queryParams]="{uri:graphExplorerUri, version: 'v1.0'}">
          <mat-icon class="native-id-link button clickable">open_in_new</mat-icon>
        </a>
      </span>
    </div>
		<div class="row">
			<span class="label">Native Change Token:</span>
			<span class="value">{{treeRoot.nativeChangeToken}}</span>
		</div>
		<div class="row">
			<span class="label">Created By:</span>
			<span class="value">{{treeRoot.createdBy}}</span>
		</div>
		<div class="row">
			<span class="label">Last Modified By:</span>
			<span class="value">{{treeRoot.lastModifiedBy}}</span>
		</div>
		<div class="row">
			<span class="label">Type:</span>
			<span class="value">{{getType()}}</span>
		</div>
		<div class="row">
			<span class="label">Size:</span>
			<span class="value">{{treeRoot.size | fileSize}}</span>
		</div>
		<div class="row">
			<span class="label">Time Discovered:</span>
			<span class="value">{{treeRoot.timeDiscovered | friendlyDate}}</span>
		</div>
		<div class="row">
			<span class="label">Time Delete Detected:</span>
			<span class="value">{{treeRoot.timeDeleteDetected | friendlyDate}}</span>
		</div>
		<div class="row">
			<span class="label">Is Restored:</span>
			<span class="value">{{treeRoot.isRestored}}</span>
		</div>
		<div class="row">
			<span class="label">Include in Search:</span>
			<span class="value">{{treeRoot.includeInSearch}}</span>
		</div>
    <div class="row">
      <span class="label">Retention Purge State:</span>
      <span class="value">{{getPurgeState()}}</span>
    </div>
    <div class="row" *ngIf="!revisionsLoading && !revisionsLoaded">
      <span class="label">Revisions:</span>
      <span class="value">
        <a class="link-button clickable"
          target="_blank"
          (click)="loadRevisions()">
          <mat-icon class="revisions-link button clickable">expand_more</mat-icon>
          Load Revisions
        </a>
      </span>
    </div>
	</div>

  <mat-spinner [diameter]="20" *ngIf="revisionsLoading"></mat-spinner>
  <div class="revisions" *ngIf="revisionsLoaded">
    <div class="title">Revisions</div>
    <div class="table revision-table">
      <div class="revision-header row">
        <div class="name">Name</div>
        <div class="modifiedBy">Modified By</div>
        <div class="modifiedOn">Modified On</div>
        <div class="size">Size</div>
      </div>
      <div class="row" *ngFor="let revision of treeRoot.revisions">
        <div class="name">{{revision.name}}</div>
        <div class="modifiedBy">{{revision.modifiedBy}}</div>
        <div class="modifiedOn">{{revision.modifiedOn | friendlyDate}}</div>
        <div class="size">{{revision.size | fileSize}}</div>
      </div>
    </div>
  </div>
</div>
