<div class="main-content d-flex flex-column">
  <div class="main-header d-flex d-flex-row">
    <mat-toolbar class="main-header-toolbar mat-elevation-z6">
      <mat-toolbar-row class="title-row">
        <div class="branding-header d-flex flex-grow-1">
          <div class="breadcrumbs">
            {{orderName}} <p *ngIf="subscriptionName" style="padding-left: 5px;"> > {{subscriptionName}} ({{resourceType}})
          </div>
          <div class="d-flex flex-grow-1">
            <img *skIfIframeRemove src="https://www.skykick.com/wp-content/uploads/skykick-logo-white.svg" (click)="goToRoot()" class="sk-logo clickable">
            <img *skIfIframeShow src="https://www.skykick.com/wp-content/uploads/skykick-logo-white.svg" class="sk-logo">
            <h2 class="title-text">Backup Support Portal</h2>
          </div>
        </div>

        <div class="header-options d-flex d-flex-row" *skIfIframeRemove>
          <div *ngIf="swatTickets" class="clickable">
            <mat-icon (click)="showSwatTickets()"
              inline class="md-26"
              matBadge="{{openSwatTicketsCount > 0 ? openSwatTicketsCount : null}}"
              matTooltip="Related SWAT Tickets"
              matBadgeColor="warn">
              notification_important
            </mat-icon>
          </div>
          <div *ngIf="utcToggleEnabled" class="clickable" matTooltip="View times in UTC">
            <mat-slide-toggle (change)="onUtcChange($event)" [checked]="useUtc" class="utc-slide-toggle">UTC</mat-slide-toggle>
          </div>
          <div class="clickable">
            <mat-icon inline class="md-26" matTooltip="User Bookmarks & History" (click)="showBookmark()">
              bookmark
            </mat-icon>
          </div>
        </div>

        <!--Icon to pop into new window when in CRM-->
        <div *skIfIframeShow class="header-options-iframe d-flex d-flex-row">
          <div class="clickable">
            <sk-click-to-copy class="click-copy"
              [hoverText]="'Click to Copy BSP Link'"
              [displayIcon]="'share'"
              [copyText]="currentUrl">
            </sk-click-to-copy>
          </div>
          <div class="clickable"
            onclick="javascript:(function(){ window.open(window.location.href, '_blank'); })();"
            matTooltip="Open support portal in new window.">
            <mat-icon inline class="md-26">open_in_new</mat-icon>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <mat-sidenav-container [hasBackdrop]="false" class="d-flex sidenav-container-main" (mouseleave)="setNavIndex(0, false)">
    <mat-sidenav *ngIf="orderId" #mainNav mode="side" opened class="drawer always-open" [class.expanded]="isExpanded" (closedStart)="mainNav.open()" >
      <mat-nav-list class="nav-container">
        <sk-nav-item label="Home"
          [routerLink]="['/orders', orderId, 'order', 'order-details']"
          iconName="home"
          [isExpanded]="isExpanded"
          (customMouseEnter)="setNavIndex(0, false)">
        </sk-nav-item>

        <sk-nav-item label="Jobs"
          iconName="restore"
          [isExpanded]="isExpanded"
          [hideTooltip]="true"
          (customMouseEnter)="setNavIndex(1, true)">
        </sk-nav-item>

        <sk-nav-item label="Accounting"
          iconName="assignment"
          [isExpanded]="isExpanded"
          [hideTooltip]="true"
          (customMouseEnter)="setNavIndex(2, true)">
        </sk-nav-item>

        <sk-nav-item label="Resources"
          iconName="switch_account"
          [isExpanded]="isExpanded"
          [hideTooltip]="true"
          (customMouseEnter)="setNavIndex(3, true)">
        </sk-nav-item>

        <sk-nav-item label="M365 Tenant Actions"
          [imgSrc]="'../assets/images/icon-microsoft365.svg'"
          [isExpanded]="isExpanded"
          [hideTooltip]="true"
          (customMouseEnter)="setNavIndex(4, true)">
        </sk-nav-item>

        <sk-nav-item label="Order Actions"
          [routerLink]="['/orders', orderId, 'order', 'order-actions']"
          iconName="construction"
          [isExpanded]="isExpanded"
          (customMouseEnter)="setNavIndex(5, false)">
        </sk-nav-item>

        <sk-nav-item label="Audit Log"
          [routerLink]="['/orders', orderId, 'order', 'audit']"
          iconName="manage_search"
          [isExpanded]="isExpanded"
          (customMouseEnter)="setNavIndex(6, false)">
        </sk-nav-item>

        <hr class="push-to-bottom">

        <sk-nav-item label="Settings"
          iconName="settings"
          [isExpanded]="isExpanded"
          (customClick)="showVersionInfo()">
        </sk-nav-item>

        <mat-list-item class="clickable" (click)="isExpanded = !isExpanded;">
          <mat-icon mat-list-icon>{{ isExpanded ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="drawer-content">
      <mat-sidenav-container class="d-flex sidenav-container-secondary slow-sidenav-transition">
        <mat-sidenav #expansionNav mode="over" class="drawer" (closedStart)="setNavIndex(0, false);">
          <mat-nav-list class="nav-container">
            <!-- dynamic spacers to push the visible sub-nav down next to it's parent -->
            <div *ngFor="let item of [].constructor(selectedMenuItemIndex)">
              <mat-list-item [ngStyle]="{'visibility': 'hidden'}">
                <mat-icon mat-list-icon>horizontal_rule</mat-icon>
              </mat-list-item>
            </div>

            <mat-nav-list *ngIf="selectedMenuItemIndex == 1" class="sub-nav-list" (mouseleave)="setNavIndex(0, false);">
              <div *ngIf="!isExpanded" class="list-item-no-click d-flex flex-column">
                <span mat-line>Jobs</span>
                <mat-divider></mat-divider>
              </div>
              <sk-nav-item label="Order Jobs" [isExpanded]="true" [routerLink]="['/orders', orderId, 'order', 'jobs']">
              </sk-nav-item>
              <sk-nav-item label="Restore Jobs" [isExpanded]="true" [routerLink]="['/orders', orderId, 'order', 'restores']">
              </sk-nav-item>
              <sk-nav-item label="Snapshot Health" [isExpanded]="true" [routerLink]="['/orders', orderId, 'order', 'snapshot-health']">
              </sk-nav-item>
              <sk-nav-item label="Snapshot Frequency" [isExpanded]="true" [routerLink]="['/orders', orderId, 'snapshot-frequencies', 'manage']">
              </sk-nav-item>
              <sk-nav-item label="Reindex Jobs" [isExpanded]="true" [routerLink]="['/orders', orderId, 'order', 'reindex-history']">
              </sk-nav-item>
            </mat-nav-list>

            <mat-nav-list *ngIf="selectedMenuItemIndex == 2" class="sub-nav-list" (mouseleave)="setNavIndex(0, false);">
              <div *ngIf="!isExpanded" class="list-item-no-click d-flex flex-column">
                <span mat-line>Accounting</span>
                <mat-divider></mat-divider>
              </div>
              <sk-nav-item label="Promo" [isExpanded]="true" [routerLink]="['/orders', orderId, 'accounting', 'promo-details']">
              </sk-nav-item>
              <sk-nav-item label="Billing History" [isExpanded]="true" [routerLink]="['/orders', orderId, 'accounting', 'billing']">
              </sk-nav-item>
            </mat-nav-list>

            <mat-nav-list *ngIf="selectedMenuItemIndex == 3" class="sub-nav-list" (mouseleave)="setNavIndex(0, false);">
              <div *ngIf="!isExpanded" class="list-item-no-click d-flex flex-column">
                <span mat-line>Resources</span>
                <mat-divider></mat-divider>
              </div>
              <sk-nav-item label="Resource Search" [isExpanded]="true" [routerLink]="['/orders', orderId, 'usersgroups']">
              </sk-nav-item>
              <sk-nav-item label="Subscriptions" [isExpanded]="true" iconName="warning" [routerLink]="['/orders', orderId, 'subscriptions']">
              </sk-nav-item>
            </mat-nav-list>

            <mat-nav-list *ngIf="selectedMenuItemIndex == 4" class="sub-nav-list" (mouseleave)="setNavIndex(0, false);">
              <div *ngIf="!isExpanded" class="list-item-no-click d-flex flex-column">
                <span mat-line>M365 Tenant Actions</span>
                <mat-divider></mat-divider>
              </div>
              <sk-nav-item label="Graph Explorer" [isExpanded]="true" [routerLink]="['/orders', orderId, 'graph-explorer2']">
              </sk-nav-item>
              <sk-nav-item label="Graph Explorer (old)" [isExpanded]="true" [routerLink]="['/orders', orderId, 'graph-explorer']">
              </sk-nav-item>
              <sk-nav-item label="Azure Application" [isExpanded]="true" [routerLink]="['/orders', orderId, 'app-auth']">
              </sk-nav-item>
              <sk-nav-item label="Visualize OneDrives" [isExpanded]="true" [routerLink]="['/orders', orderId, 'onedrive-discovery']">
              </sk-nav-item>
              <sk-nav-item label="Browse Licensing" [isExpanded]="true" [routerLink]="['/orders', orderId, 'order', 'licensing']">
              </sk-nav-item>
            </mat-nav-list>
          </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content class="inner-drawer-content d-flex flex-grow-1">
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<sk-side-drawer [open]="drawerOpened" (cancel)="closeDrawer()">
  <sk-bookmark-list *ngIf="drawerOpened" (navigate)="onNavigate($event)"></sk-bookmark-list>
</sk-side-drawer>

<sk-side-drawer [open]="swatTicketsDrawerOpened" (cancel)="closeSwatTicketsDrawer()">
  <sk-swat-ticket-list *ngIf="swatTicketsDrawerOpened" (navigateSwatTicket)="onNavigateSwatTicket($event)"
    (navigateToSwatBoard)="onNavigateToSwatBoard()" [swatTickets]="swatTickets">
  </sk-swat-ticket-list>
</sk-side-drawer>
